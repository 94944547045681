<template>
  <div class="message">
    <div class="panel-title">
      {{ $t("message.notificationManagement") }}
    </div>

    <div class="search-query-container">
      <div class="search-query" style="min-width: 260px;">
        <el-form ref="form" :model="form" :inline="true" label-position="top">
          <el-row :gutter="20">
            <el-col :span="12"
              ><el-form-item :label="$t('message.title')"> <el-input v-model="messageTitle"></el-input> </el-form-item
            ></el-col>
            <el-col :span="12"
              ><el-form-item :label="$t('message.type')">
                <el-select v-model="messageType">
                  <el-option :value="[]" :label="$t('message.all')"></el-option>
                  <el-option
                    v-for="(item, index) in messageTypeList"
                    :value="item.messageTypeId"
                    :label="item.messageTypeName"
                    :key="item.messageTypeId"
                  ></el-option>
                </el-select> </el-form-item
            ></el-col>
          </el-row>

          <div class="search-btn">
            <el-button class="confirm" @click="getList(1)" v-permission="'Message.Message'">{{
              $t("message.search")
            }}</el-button>
          </div>
        </el-form>
      </div>
    </div>
    <el-button
      class="confirm"
      style="margin-top:20px;margin-bottom: 20px;"
      @click="showDialog"
      v-permission="'Message.MessageAdd'"
      >{{ $t("message.create") }}</el-button
    >
    <div class="result-background">
      <div class="result-table" v-loading="loading">
        <el-table :data="messageData">
          <el-table-column prop="messageType" :label="$t('message.type')" v-if="messageTypeList.length > 0">
            <template slot-scope="scope">
              {{ messageTypeListForDisplay.find(item => item.messageTypeId === scope.row.messageType).messageTypeName }}
            </template>
          </el-table-column>
          <el-table-column prop="messageTitle" :label="$t('message.title')"></el-table-column>
          <el-table-column prop="creater" :label="$t('message.creator')"></el-table-column>
          <el-table-column prop="edit" :label="$t('message.editType')">
            <template slot-scope="scope">
              {{ scope.row.edit ? $t("message.editable") : $t("message.notEditable") }}
            </template>
          </el-table-column>
          <el-table-column prop="createDate" :label="$t('message.releaseTime')"></el-table-column>
          <el-table-column prop="date" :label="$t('message.operation')">
            <template slot-scope="scope">
              <div class="tableBtnGroup">
                <el-button size="mini" type="text" @click="getDetails(scope.row)" v-permission="'Message.MessageEdit'"
                  >{{ $t("message.details") }}
                  <i class="el-icon-arrow-right"></i>
                </el-button>
                <el-button
                  size="mini"
                  type="text"
                  class="grey"
                  @click="delMessage(scope.row)"
                  v-permission="'Message.MessageDelete'"
                  >{{ $t("message.delete") }}
                  <i class="el-icon-arrow-right"></i>
                </el-button>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          key="messagepagination"
          v-if="messageData.length > 0"
          background
          layout=" prev, pager, next, sizes, total"
          :total="total"
          :page-size="currentPageSize"
          :page-sizes="[10, 15, 20]"
          :current-page="currentPage"
          @current-change="currentChange"
          @size-change="sizeChange"
          class="common-pagination"
        >
        </el-pagination>
      </div>
    </div>

    <el-dialog
      :title="editKey > 0 ? $t('message.details') : $t('message.create')"
      :visible.sync="dialogVisible"
      width="60%"
      top="6vh"
      :close-on-click-modal="false"
      @closed="handleClose"
      ref="messageDialog"
      :destroy-on-close="true"
    >
      <el-form
        v-loading="loading"
        ref="messageForm"
        :model="getMsgData(editKey)"
        :disabled="!editType"
        label-position="top"
      >
        <el-row :gutter="20">
          <el-col :span="14">
            <el-form-item
              :label="$t('message.type')"
              class="is-required"
              :rules="[{ required: true, message: '通知種別を選択してください', trigger: 'close' }]"
              prop="messageType"
            >
              <el-select v-model="getMsgData(editKey).messageType">
                <el-option
                  v-for="(item, index) in messageTypeList"
                  :value="item.messageTypeId"
                  :label="item.messageTypeName"
                  :key="item.messageTypeId"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="5">
            <el-form-item :label="$t('message.duedit')" v-if="editKey < 0">
              <el-switch v-model="getMsgData(editKey).edit"></el-switch>
            </el-form-item>
          </el-col>
          <el-col :span="5">
            <el-form-item :label="$t('message.sendEmail')" v-if="$settings.sendEmail === true && isAdmin">
              <el-switch v-model="getMsgData(editKey).sendEmail"></el-switch>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item
          :label="`${$t('message.title')} (${$t('message.titleTips')})`"
          class="is-required"
          prop="messageTitle"
          :rules="[{ validator: checkRequiredTitle, trigger: 'close' }]"
        >
          <el-input v-model="getMsgData(editKey).messageTitle"></el-input>
        </el-form-item>
        <el-form-item
          :label="$t('message.content')"
          class="is-required"
          prop="messageBody"
          :rules="[{ validator: checkRequired, trigger: 'close' }]"
        >
          <template slot="label">
            <span>{{ $t("message.content") }} </span>
            <span style="color:#ec1919;">({{ $t("message.contentTips") }})</span>
          </template>
          <vue-editor
            v-loading="uploadFileloading"
            :disabled="!editType"
            class="ql-editor"
            style="clear:both;padding:0px 0px;white-space: nowrap;"
            v-model="getMsgData(editKey).messageBody"
            :editor-toolbar="customToolbar"
            useCustomImageHandler
            @image-added="handleImageAdded"
          />
        </el-form-item>

        <el-row>
          <el-col :span="24">
            <el-form-item
              :label="$t('message.receiver')"
              class="is-required"
              prop="messageUser"
              :rules="[{ validator: checkUserSelect, trigger: 'close' }]"
            >
              <el-radio-group
                style="line-height: 44px;"
                v-model="getMsgData(editKey).userScope"
                @change="
                  multipleSelectionAll = [];
                  userList = [];
                  userSelectList = [];
                  userSelectListTotal = 0;
                  getMsgData(editKey).conditions = {};
                "
              >
                <el-radio label="all">{{ $t("message.allUsers") }}</el-radio>
                <el-radio label="entadmin">{{ $t("加盟店指定") }}</el-radio>
                <el-radio label="custom">{{ $t("message.specifiedUser") }}</el-radio>
              </el-radio-group>
              <el-row v-if="!(getMsgData(editKey).userScope === 'all')">
                <el-col>
                  <el-button
                    type="primary"
                    style="width:150px;margin-right:20px;"
                    @click="
                      userSelectDialogVisible = true;
                      multipleSelectionAll = [];
                      userList = [];
                      userSelectList = [];
                      userSelectListTotal = 0;
                      getMsgData(editKey).conditions = {};
                    "
                  >
                    {{ $t("絞り込み") }}</el-button
                  >
                  <span
                    v-if="JSON.stringify(getMsgData(editKey).conditions) === '{}' || !getMsgData(editKey).conditions"
                    >{{ multipleSelectionAll.length }} を選択しました</span
                  >
                  <span v-else>全ユーザーを選択，総計{{ userSelectedPreviewListTotal }}名</span>
                </el-col>
                <el-table :data="multipleSelectionAll" v-if="multipleSelectionAll.length > 0">
                  <el-table-column prop="userName" :label="$t('ユーザー名')"></el-table-column>
                  <el-table-column prop="userFullName" :label="$t('名前')"></el-table-column>
                  <el-table-column prop="entDisplayName" :label="$t('所属企業')"></el-table-column>
                </el-table>
              </el-row>
            </el-form-item>
          </el-col>
          <!-- <el-col :span="12" v-if="radio == 2 && editKey > 0"
            ><div style="text-align: right;padding-top:38px">
              <el-button size="mini" type="text" :disabled="false" @click="getSentMessagesList()">受取先一覧</el-button>
            </div>
          </el-col> -->
        </el-row>

        <el-form-item v-if="radio == 2 && false">
          <el-input v-model="searchUserString" class="input-with-select" clearable>
            <el-select v-model="select" slot="prepend" @change="changeSelect" style="width:150px">
              <el-option :label="$t('enterprise')" value="1"></el-option>
              <el-option :label="$t('name')" value="2"></el-option>
            </el-select>
            <el-button slot="append" icon="el-icon-search" v-on:click="getUserList"></el-button>
          </el-input>
        </el-form-item>
        <el-form-item
          v-if="radio == 2 && false"
          prop="messageUser"
          :rules="[{ validator: checkUserSelect, trigger: 'close' }]"
        >
          <el-table
            ref="userTable"
            :data="userList"
            :max-height="500"
            class="editor"
            v-loading="userLoading"
            :row-key="getRowKeys"
            @selection-change="handleSelectionChange"
          >
            <el-table-column type="selection" :reserve-selection="true" width="55"> </el-table-column>
            <el-table-column prop="userName" :label="$t('message.userName')"></el-table-column>
            <el-table-column prop="userFullName" :label="$t('message.name')"></el-table-column>
            <el-table-column prop="entDisplayName" :label="$t('message.enterprise')"></el-table-column>
          </el-table>
          <el-pagination
            background
            layout=" prev, pager, next, sizes, total"
            :total="totalUserCount"
            :page-size="currentUserPageSize"
            :page-sizes="[10, 15, 20]"
            :current-page="currentUserPage"
            @current-change="currentUserChange"
            @size-change="userSizeChange"
            class="common-pagination"
          >
          </el-pagination>
        </el-form-item>
      </el-form>

      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">{{ $t("cancel") }}</el-button>
        <el-button
          v-if="editType"
          type="primary"
          @click="postMessage"
          class="confirm"
          v-loading="editLoading"
          :disabled="uploadFileloading || editLoading"
          >{{ editKey > 0 ? $t("message.edit") : $t("confirm") }}</el-button
        >
      </span>
    </el-dialog>

    <modal-dialog
      :dialog-title="$t('message.recipientList')"
      :show-dialog.sync="sentDialogVisible"
      width="60%"
      top="6vh"
      :show-footer="false"
      style="margin-top:100px"
    >
      <div v-loading="loading">
        <el-input placeholder="" v-model="sentListFilter" class="input-with-select">
          <el-select v-model="sentListFilterType" slot="prepend" placeholder="" style="width:150px;">
            <el-option label="ユーザー名" value="userName"></el-option>
            <el-option label="名前" value="userFullName"></el-option>
            <el-option label="所属企業" value="entDisplayName"></el-option>
            <el-option label="企業コード" value="entId"></el-option>
          </el-select>
          <el-button slot="append" icon="el-icon-search" @click="getSentMessagesList(1, sentPageSize)"></el-button>
        </el-input>
        <el-table :data="sentMessagesList" style="margin-top:40px;">
          <el-table-column prop="userName" :label="$t('ユーザー名')"></el-table-column>
          <el-table-column prop="userFullName" :label="$t('名前')"></el-table-column>
          <el-table-column prop="entDisplayName" :label="$t('所属企業')"></el-table-column>
          <el-table-column prop="entId" :label="$t('企業コード')"></el-table-column>
        </el-table>
        <el-pagination
          key="messagepagination"
          v-if="sentMessagesList.length > 0"
          background
          layout=" prev, pager, next, sizes, total"
          :total="sentMessagesTotal"
          :page-size="sentPageSize"
          :page-sizes="[10, 15, 20]"
          class="common-pagination"
          :current-page="sentCurrentUserPage"
          @current-change="sentCurrentUserChange"
          @size-change="sentUserSizeChange"
        >
        </el-pagination>
      </div>
    </modal-dialog>

    <modal-dialog
      :dialog-title="$t('ユーザーの選択')"
      :show-dialog.sync="userSelectDialogVisible"
      width="60%"
      top="6vh"
      :show-footer="false"
      style="margin-top:100px"
    >
      <el-form :model="userSelectForm" ref="userSelectFormRef">
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="ユーザー名\名前\所属企業">
              <el-input
                v-model="userSelectForm.userFilter"
                class="input-with-select"
                clearable
                @keyup.enter.native="getUserList()"
              >
                <el-select v-model="userSelectForm.searchType" slot="prepend" style="width: 100%;">
                  <el-option label="ユーザー名" value="userName"></el-option>
                  <el-option label="名前" value="userFullName"></el-option>
                  <el-option label="所属企業" value="entName"></el-option>
                </el-select>
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="決済種別">
              <el-select v-model="userSelectForm.payBrand" multiple style="width: 100%;" clearable>
                <el-option
                  v-for="item in payBrandList"
                  :key="item.name"
                  :label="item.name"
                  :value="item.category"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col style="text-align: right;">
            <el-button type="primary" @click="getUserList(1, userSelectPageSize, $event)">{{
              $t("message.search")
            }}</el-button>
          </el-col>
        </el-row>
      </el-form>
      <div v-loading="userSelectLoading">
        <el-table
          :data="userList"
          ref="userSelectTableRef"
          @select="userSelectionChange"
          @select-all="userSelectionAllChange"
        >
          <el-table-column type="selection" width="55"> </el-table-column>
          <el-table-column prop="userName" :label="$t('ユーザー名')"></el-table-column>
          <el-table-column prop="userFullName" :label="$t('名前')"></el-table-column>
          <el-table-column prop="entDisplayName" :label="$t('所属企業')"></el-table-column>
        </el-table>

        <el-pagination
          v-if="userList.length > 0"
          style="text-align: right; margin: 20px"
          background
          layout="prev, pager, next,sizes,total"
          :total="userSelectListTotal"
          :current-page="userSelectPageIndex"
          :page-size="userSelectPageSize"
          @size-change="getUserList(1, $event)"
          @current-change="getUserList($event)"
          :page-sizes="[10, 20, 30, 50, 100]"
        ></el-pagination>
      </div>

      <el-row>
        <el-col :span="12"
          ><el-button
            type="primary"
            @click="selectAllBackendUser"
            class="confirm"
            :disabled="userSelectListTotal === 0"
            >{{ $t(`全ユーザーを選択`) }}</el-button
          ></el-col
        >
        <el-col :span="12" style="text-align: right;">
          <el-button
            @click="
              userSelectDialogVisible = false;
              userSelectList = [];
              userSelectListTotal = 0;
            "
            >{{ $t("cancel") }}</el-button
          >
          <el-button type="primary" @click="saveSelectUser" class="confirm" :disabled="userSelectList.length === 0">{{
            $t("confirm")
          }}</el-button></el-col
        >
      </el-row>
    </modal-dialog>
  </div>
</template>

<script>
import pagination from "@/mixins/pagination";
import {
  sysUserList as getSysUserList,
  sysUserList,
  manageMessageList,
  createSystemMessage,
  sysMessageDetail,
  updateSystemMessage,
  deleteMessage,
  uploadImage,
  sysSendMessageUserList,
  getSendMessageType,
  getReceiveMessageType
} from "api/message";
import { getPayBrand } from "api/trade";
import { isSuperAdmin, isAdmin } from "utils/index";

//富文本编辑器
import { VueEditor } from "vue2-editor";
export default {
  name: "MessageManagement",
  mixins: [pagination],
  components: {
    VueEditor
  },
  data() {
    return {
      conditions: {
        allOemUser: false,
        userFullName: "",
        userName: "",
        entName: "",
        psps: []
      },
      //addForm
      form: {
        messageType: "",
        messageBody: "",
        messageTitle: "",
        edit: false,
        sendEmail: false,
        userScope: "all",
        conditions: {}
      },
      //editForm
      msgDetailsForm: {},
      userSelectLoading: false,
      userSelectSwitch: true,
      sysUserList: [],
      payBrandList: {},
      userSelectedPreviewList: [],
      // userSelectedPreviewPageIndex: 1,
      userSelectedPreviewListTotal: 0,
      userSelectList: [],
      userList: [],
      userSelectListTotal: 0,
      userSelectPageIndex: 1,
      userSelectPageSize: 10,
      userSelectForm: {
        payBrand: [],
        userFilter: "",
        searchType: "userName"
      },

      userSelectDialogVisible: false,
      messageTypeListForDisplay: [],
      messageTypeList: [],
      customToolbar: [
        [{ header: [false, 1, 2, 3, 4, 5, 6] }], //标题
        ["bold", "italic", "underline", "strike"], //加粗\斜体\下划线\文字中间一横
        [{ align: "" }, { align: "center" }, { align: "right" }, { align: "justify" }], //对齐
        ["blockquote"], //引用
        [{ list: "ordered" }, { list: "bullet" }], //有序无序列表
        [{ indent: "-1" }, { indent: "+1" }], //缩进
        [{ background: [] }, { color: [] }], //背景色
        ["link", "image", "clean"] //链接、图片、清除字体样式
      ],
      userFilter: "",
      editorOption: {
        // Some Quill options...
      },
      editor: null,
      loading: true,
      editLoading: false,
      userLoading: false,
      uploadFileloading: false,
      messageType: [],
      messageTitle: "",
      messageData: [],
      userList: [],
      detailDrawer: false,
      detailInfo: {},
      dialogVisible: false,
      editKey: -1,
      editType: true,
      searchUserString: "",
      select: "1",
      entName: "",
      userFullName: "",
      totalUserCount: 0,
      currentUsers: [],
      multipleSelectionAll: [], // 所有选中的数据包含跨页数据
      multipleSelection: [], // 当前页选中的数据
      idKey: "userId", // 标识列表数据中每一行的唯一键的名称(需要按自己的数据改一下)
      radio: 1,
      sentMessagesList: [],
      sentDialogVisible: false,
      sentListFilterType: "userName",
      sentListFilter: "",
      currentUserPageSize: 10,
      sentMessagesTotal: 0,
      sentPageSize: 10,
      sentCurrentUserPage: 1
    };
  },

  methods: {
    selectAllBackendUser() {
      console.log("全选选定条件", { ...this.conditions });
      //写数据
      this.getMsgData(this.editKey).conditions = this.conditions;
      this.getMsgData(this.editKey).conditions.total = this.userSelectListTotal;
      // this.userSelectedPreviewList = JSON.parse(JSON.stringify(this.userList));
      this.userSelectedPreviewListTotal = this.userSelectListTotal;
      //关窗口
      this.userSelectDialogVisible = false;
      //清理内部数据
      this.userSelectList = [];
      this.userSelectListTotal = 0;
      this.userList = [];
    },
    saveSelectUser() {
      this.userSelectList.forEach(bItem => {
        const isDuplicate = this.multipleSelectionAll.some(aItem => aItem.userId === bItem.userId);

        if (!isDuplicate) {
          this.multipleSelectionAll.push(bItem);
        }
      });
      this.userSelectDialogVisible = false;
      this.userSelectList = [];
    },
    userSelectionAllChange(selection) {
      console.log("全选操作", selection);
      if (selection.length > 0) {
        console.log("全选加", selection);
        selection.forEach(item => {
          if (!this.userSelectList.some(user => user.userId === item.userId)) {
            this.userSelectList.push(item);
          }
        });
      } else {
        console.log("全选减", selection);
        this.userList.forEach(user => {
          if (this.userSelectList.some(selectUser => selectUser.userId === user.userId)) {
            this.userSelectList = this.userSelectList.filter(selectUser => selectUser.userId !== user.userId);
          }
        });
      }
    },
    userSelectionChange(selection, current) {
      console.log(selection, current);
      console.log("触发了selection-change事件", selection, this.userSelectSwitch);
      if (this.userSelectSwitch) {
        if (this.userSelectList.some(item => item.userId === current.userId)) {
          console.log("减一个:", current);
          this.userSelectList = this.userSelectList.filter(item => item.userId !== current.userId);
        } else {
          console.log("加一个:", current);
          this.userSelectList.push(current);
        }
        // this.userSelectList = selection;
      }
    },
    getUserList(pageIndex = 1, pageSize = this.userSelectPageSize) {
      this.userSelectPageSize = pageSize;
      this.userSelectPageIndex = pageIndex;

      let j = {
        ...(this.userSelectForm.userFilter ? { [this.userSelectForm.searchType]: this.userSelectForm.userFilter } : {}),
        ...(this.userSelectForm.payBrand ? { psps: this.userSelectForm.payBrand } : {}),
        ...(this.getMsgData(this.editKey).userScope === "entadmin" ? { allOemUser: false } : {}),
        ...(this.getMsgData(this.editKey).userScope === "custom" ? { allOemUser: true } : {})
      };

      //写全选缓存
      this.conditions = j;

      this.userSelectLoading = true;

      getSysUserList({ ...j, pageSize, pageIndex }).then(res => {
        console.log(res);
        this.userSelectLoading = false;
        if (res.statusCode === 200 && res.resultStatus === "Success") {
          this.userSelectSwitch = false;
          this.userList = res.result;
          if (this.userSelectList.length > 0) {
            console.log("存在已选择的数据", this.userSelectList);
            const selectedRows = this.userList.filter(row =>
              this.userSelectList.some(selected => selected.userId === row.userId)
            );
            this.$nextTick(() => {
              selectedRows.forEach(row => {
                this.$refs.userSelectTableRef.toggleRowSelection(row, true);
              });
              // 不管selectedRows是否为空，都在这里设置userSelectSwitch为true
              this.userSelectSwitch = true;
            });
          } else {
            // 如果没有已选择的数据，直接设置userSelectSwitch为true
            this.userSelectSwitch = true;
          }

          this.userSelectListTotal = res.totalCount;
        }
      });
    },
    showDialog() {
      this.dialogVisible = true;
      this.editType = true;
    },
    handleClose() {
      this.editKey = -1;
      this.radio = 1;
      this.form.messageTitle = "";
      this.form.messageBody = "";
      this.form.edit = false;
      this.form.sendEmail = false;
      this.form.conditions = {};
      this.form.userScope = "all";
      this.$refs["messageForm"].clearValidate();
    },

    delMessage(row) {
      //确定要删除该通知吗？（将导致通知信息在关联终端无法展示）
      this.$confirm(this.$t("message.deleteMessageTips")).then(_ => {
        deleteMessage(row).then(res => {
          if (res.statusCode === 200 && res.resultStatus === "Success") {
            this.getList(1);
          }
        });
      });
    },
    postMessage() {
      // 再执行一次记忆勾选数据匹配，目的是为了在当前页操作勾选后直接获取选中数据
      // this.changePageCoreRecordData();
      this.$refs.messageForm.validate(res => {
        if (res) {
          this.editLoading = true;
          let j = {
            messageType: this.getMsgData(this.editKey).messageType,
            messageTitle: this.getMsgData(this.editKey).messageTitle,
            messageBody: this.getMsgData(this.editKey).messageBody,
            edit: this.getMsgData(this.editKey).edit,
            sendEmail: this.getMsgData(this.editKey).sendEmail,
            conditions: this.getMsgData(this.editKey).conditions,
            userScope: this.getMsgData(this.editKey).userScope
          };
          if (this.getMsgData(this.editKey).userScope === "all") {
            j.allUsers = true;
            j.messageUsers = [];
          } else {
            j.allUsers = false;
            // let userSelected = this.multipleSelectionAll;
            // let userSelected = this.multipleSelectionAll.map(item => {
            //   // return {
            //   //   userId: item.userId,
            //   //   userName: item.userName
            //   // };
            // });
            j.messageUsers = this.multipleSelectionAll;
          }
          if (this.getMsgData(this.editKey).messageId) {
            updateSystemMessage(Object.assign(j, { messageId: this.getMsgData(this.editKey).messageId })).then(res => {
              this.editLoading = false;
              if (res.statusCode === 200 && res.resultStatus === "Success") {
                this.multipleSelectionAll = [];
                this.dialogVisible = false;
                this.getList(1);
                this.$message.success(this.$t("success"));
              } else {
                this.$message.error(this.$t("fail"));
              }
            });
          } else {
            createSystemMessage(j).then(res => {
              this.editLoading = false;
              if (res.statusCode === 200 && res.resultStatus === "Success") {
                this.form.messageTitle = "";
                this.form.messageBody = "";
                this.dialogVisible = false;
                this.getList(1);
                this.$message.success(this.$t("success"));
              } else {
                this.$message.error(this.$t("fail"));
              }
            });
          }
        } else {
          return false;
        }
      });
    },
    //获取已发送消息列表
    getSentMessagesList(pageIndex = 1, pageSize = this.sentPageSize) {
      this.loading = true;

      let j = { messageId: this.msgDetailsForm.messageId, pageIndex, pageSize };

      console.log(j);

      if (!(this.sentListFilter === "")) {
        j[this.sentListFilterType] = this.sentListFilter;
      }

      sysSendMessageUserList(j).then(res => {
        this.loading = false;
        this.sentDialogVisible = true;
        if (res.statusCode === 200 && res.resultStatus === "Success") {
          console.log(res);
          this.sentMessagesList = [];
          this.sentMessagesList = res.result;
          this.sentMessagesTotal = res.totalCount;
          // this.multipleSelectionAll = res.result;
          // this.dialogVisible = true;
        } else {
          this.$message.error(this.$t("fail"));
        }
      });
    },
    //获取消息详情
    getDetails(row) {
      this.loading = true;
      sysMessageDetail({ messageId: row.messageId }).then(res => {
        this.loading = false;
        if (res.statusCode === 200 && res.resultStatus === "Success") {
          this.editKey = 1;
          this.dialogVisible = true;
          this.userSelectedPreviewListTotal = 0;
          this.multipleSelectionAll = [];

          if (res.result.conditions) {
            this.userSelectedPreviewListTotal = Number(JSON.parse(res.result.conditions).total);
            res.result.conditions = JSON.parse(res.result.conditions);
          }
          if (res.result.messageUsers) {
            this.multipleSelectionAll = res.result.messageUsers;
          }

          this.editType = res.result.edit;
          this.msgDetailsForm = res.result;
          this.currentUsers = res.result.messageUsers;

          // if (res.result.allUsers) {
          //   this.radio = 1;
          // } else {
          //   this.radio = 2;
          //   this.currentUsers = res.result.messageUsers;
          //   this.multipleSelectionAll = res.result.messageUsers;
          //   this.getUserList(1);
          // }
        }
      });
    },
    handleImageAdded: function(file, Editor, cursorLocation, resetUploader) {
      if (file.size / 1024 / 1024 > 2) {
        // 限制上传的图片的大小
        this.$message.warning(this.$t("uploadImageSizeLimit"));
        resetUploader();
        return;
      }
      this.uploadFileloading = true;
      var formData = new FormData();
      formData.append("file", file);
      uploadImage(formData).then(result => {
        if (result.statusCode === 200 && result.resultStatus === "Success") {
          const url = result.result; // Get url from response
          Editor.insertEmbed(cursorLocation, "image", url);
        } else {
          this.$message.error(this.$t("uploadFail"));
        }
        resetUploader();
        this.uploadFileloading = false;
      });
    },
    //获取消息列表
    getList(pageIndex = 1, pageSize = this.currentPageSize) {
      this.loading = true;
      this.currentPage = pageIndex;
      let messageType = this.messageType;
      let messageTitle = this.messageTitle;
      let j = {
        messageTitle,
        pageIndex,
        pageSize,
        IgnoreExpiration: true
      };
      if (messageType) {
        Object.assign(j, { messageType: messageType });
      }
      manageMessageList(j).then(response => {
        if (response.statusCode === 200 && response.resultStatus === "Success") {
          this.total = response.totalCount;
          this.messageData = response.result;
          this.loading = false;
        }
      });
    },
    changeSelect(value) {
      if (value === "1") {
        this.userFullName = "";
        this.entName = this.searchUserString.trim();
      } else {
        this.entName = "";
        this.userFullName = this.searchUserString.trim();
      }
    },

    // 设置选中的方法
    setSelectRow() {
      this.$nextTick(() => {
        if (!this.multipleSelectionAll || this.multipleSelectionAll.length <= 0) {
          return;
        }
        // 标识当前行的唯一键的名称
        let idKey = this.idKey;
        let selectAllIds = [];
        this.multipleSelectionAll.forEach(row => {
          selectAllIds.push(row[idKey]);
        });
        this.$refs.userTable.clearSelection();
        for (var i = 0; i < this.userList.length; i++) {
          if (selectAllIds.indexOf(this.userList[i][idKey]) >= 0) {
            // 设置选中，记住table组件需要使用ref="table"
            this.$refs.userTable.toggleRowSelection(this.userList[i], true);
          }
        }
      });
    },
    // 记忆选择核心方法
    changePageCoreRecordData() {
      // 标识当前行的唯一键的名称
      let idKey = this.idKey;
      let that = this;
      // 如果总记忆中还没有选择的数据，那么就直接取当前页选中的数据，不需要后面一系列计算
      if (this.multipleSelectionAll.length <= 0) {
        this.multipleSelectionAll = this.multipleSelection;
        return;
      }
      // 总选择里面的key集合
      let selectAllIds = [];
      this.multipleSelectionAll.forEach(row => {
        selectAllIds.push(row[idKey]);
      });
      let selectIds = [];
      // 获取当前页选中的id
      this.multipleSelection.forEach(row => {
        selectIds.push(row[idKey]);
        // 如果总选择里面不包含当前页选中的数据，那么就加入到总选择集合里
        if (selectAllIds.indexOf(row[idKey]) < 0) {
          that.multipleSelectionAll.push(row);
        }
      });
      let noSelectIds = [];
      // 得到当前页没有选中的id
      this.userList.forEach(row => {
        if (selectIds.indexOf(row[idKey]) < 0) {
          noSelectIds.push(row[idKey]);
        }
      });
      noSelectIds.forEach(id => {
        if (selectAllIds.indexOf(id) >= 0) {
          for (let i = 0; i < that.multipleSelectionAll.length; i++) {
            if (that.multipleSelectionAll[i][idKey] == id) {
              // 如果总选择中有未被选中的，那么就删除这条
              that.multipleSelectionAll.splice(i, 1);
              break;
            }
          }
        }
      });
    },

    radioChange(value) {
      // if (value === 2 && this.editKey > 0) {
      //   this.getUserList(1);
      // } else {
      //   if (this.editKey < 0) {
      //     this.currentUsers = [];
      //     this.$nextTick(() => {
      //       this.$refs.userTable.clearSelection();
      //     });
      //   }
      //   this.multipleSelectionAll = this.currentUsers;
      // }
    },

    currentUserChange(page) {
      // 改变页的时候调用一次
      this.changePageCoreRecordData();
      this.currentUserPage = page;
      this.getUserList(page);
    },
    userSizeChange(size) {
      this.changePageCoreRecordData();
      this.currentUserPageSize = size;
      this.getUserList(1, size);
    },
    sentCurrentUserChange(page) {
      // 改变页的时候调用一次
      // this.changePageCoreRecordData();
      this.sentCurrentUserPage = page;
      this.getSentMessagesList(page);
    },
    sentUserSizeChange(size) {
      // this.changePageCoreRecordData();
      this.sentPageSize = size;
      this.sentCurrentUserPage = 1;
      this.getSentMessagesList(1, size);
    },
    getRowKeys(row) {
      return row.userId;
    },
    handleSelectionChange(rows) {
      this.multipleSelection = rows;
    },
    getUserList2(pageIndex = 1, pageSize = this.currentUserPageSize) {
      this.userLoading = true;
      this.currentUserPage = pageIndex;
      if (this.select === "1") {
        this.userFullName = "";
        this.entName = this.searchUserString.trim();
      } else {
        this.entName = "";
        this.userFullName = this.searchUserString.trim();
      }
      sysUserList({
        EntName: this.entName,
        UserFullName: this.userFullName,
        pageSize,
        pageIndex
      }).then(response => {
        this.userLoading = false;
        if (response.resultStatus === "Success" && response.statusCode === 200) {
          this.userList = response.result;
          this.totalUserCount = response.totalCount;
          this.setSelectRow();
        }
      });
    },
    checkRequired(rule, value, callback) {
      let reg = /.+/;
      if (reg.test(value)) {
        callback();
      } else {
        callback(new Error(this.$t("message.plzEnterTheContent")));
      }
    },
    checkRequiredTitle(rule, value, callback) {
      let reg = /.+/;
      if (reg.test(value)) {
        callback();
      } else {
        callback(new Error(this.$t("message.plzEnterTheTitle")));
      }
    },
    checkUserSelect(rule, value, callback) {
      if (this.getMsgData(this.editKey).userScope === "all") {
        callback();
      } else {
        if (JSON.stringify(this.getMsgData(this.editKey).conditions) !== "{}" || this.multipleSelectionAll.length > 0) {
          callback();
        } else {
          callback(new Error(this.$t("message.plzChooseTheReceiver")));
        }
      }
    }
  },
  created() {
    let getSendMessageTypeFn = getSendMessageType();
    let getReceiveMessageTypeFn = getReceiveMessageType();
    let getPayBrandFn = getPayBrand();

    Promise.all([getSendMessageTypeFn, getReceiveMessageTypeFn, getPayBrandFn])
      .then(res => {
        console.log("messageType:", res[0]);
        if (res[0].statusCode === 200 && res[0].resultStatus === "Success") {
          this.messageTypeList = res[0].result;
        }

        if (res[1].statusCode === 200 && res[1].resultStatus === "Success") {
          this.messageTypeListForDisplay = res[1].result;
        }
        if (res[2].statusCode === 200 && res[2].resultStatus === "Success") {
          this.payBrandList = res[2].result;
        }
      })
      .then(() => {
        this.getList();
        // this.getUserList();
      });
  },
  computed: {
    isAdmin() {
      return isAdmin();
    },
    getMsgData: {
      get: function() {
        return function(editKey) {
          if (editKey < 0) {
            return this.form;
          } else {
            return this.msgDetailsForm;
          }
        };
      }
    }
  }
};
</script>
<style lang="scss">
@import "~@/styles/mixin.scss";
@import "~@/styles/variables.scss";
.dialog-box {
  position: relative;
  top: inherit;
  left: inherit;
  transform: none;
}
.ql-tooltip {
  z-index: 99999;
  left: 0 !important;
}
.ql-editing {
  z-index: 99999;
  left: 0 !important;
}
.ql-container {
  height: 20rem !important;
}
.is-error .editor {
  border-color: #f56c6c;
}
.hide {
  display: none;
}
.notification-container {
  background: #fff;
  box-shadow: 0 2px 12px 0 rgba(103, 95, 119, 0.1);
  border-radius: 8px;
  padding: 10px 24px;
  box-sizing: border-box;
  margin-top: 24px;

  ::v-deep & > div {
    border-bottom: 1px dashed rgba(0, 0, 0, 0.12);

    &:last-child {
      border: none;
    }
  }
}

.other-notice-body {
  // height: 66px;
  border-bottom: 1px dotted #d8d8d8;
  cursor: pointer;
}
.other-notice-title {
  line-height: 36px;
  word-break: break-all;
}
.other-notice-date {
  color: #86858a;
  font-size: 12px;
  line-height: 16px;
}
.other-notice-body:last-of-type {
  border: 0;
}
</style>
